export function getCurrentEnv() {
    return "";
}

export function getEipWebDomain() {
    // return `http://52.83.248.104:80`
    return `https://eip.dev.origin.polestar.cn`
}

export function getEipApiDomain() {
    // return `http://52.83.248.104:80`
    return `https://api.dev.origin.polestar.cn`
}
