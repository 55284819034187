import { getDetailByVinApi, getEipModelTypeApi } from "../api/eip";
import { EipInterface } from "../interface/eip";
import { getEipWebDomain } from "../api/env";

enum ModelName {
    "polestar-1" = "Polestar 1",
    "polestar-2" = "Polestar 2",
    "polestar-3" = "Polestar 3",
    "polestar-4" = "Polestar 4",
    "polestar-5" = "Polestar 5"
}

// 获取重构后的初始化数据
export async function getEipAllData(): Promise<EipInterface.RefactorAllDate> {
    let tabs: { label: string, code: string }[] = [];
    const resData = await getEipModelTypeApi();
    const res = resData.data;
    let result = {} as EipInterface.RefactorData;
    for (let i = 0; i < res.file.length; i++) {
        const item = res.file[i];
        if (!result.hasOwnProperty(item.modelName)) {
            result[item.modelName] = [];
            result[item.modelName].push(
                {
                    title: item.modelName,
                    categories: [
                        {
                            title: item.modelTypeCode,
                            items: [getLinkData(item)]
                        }
                    ]
                }
            );
            tabs.push({
                label: ModelName[item.modelName], code: item.modelName
            })
        } else {
            let findIndex = result[item.modelName][0].categories.findIndex((ele: { title: string }) => ele.title === item.modelTypeCode);
            if (findIndex > -1) {
                result[item.modelName][0].categories[findIndex].items.push(
                    getLinkData(item)
                )
            } else {
                result[item.modelName][0].categories.push(
                    {
                        title: item.modelTypeCode,
                        items: [getLinkData(item)]
                    }
                )
            }
        }
    }
    return {
        data: result,
        tabs
    };

    function getLinkData(item: EipInterface.File1) {
        return { title: item.fileName, url: `${getEipWebDomain()}/${item.filePath}/${item.fileName}` };
    }
}

// 通过vin号获取对应数据
export async function getDetailInfoByVin(vin: string): Promise<EipInterface.DetailByDetail> {
    const res = await getDetailByVinApi(vin) as unknown as EipInterface.DetailByDetail;
    if (res.rtn) {
        res.data.file.filePath = `${getEipWebDomain()}/${res.data.file.filePath}/${res.data.file.fileName}`
    }
    return res;
}

// 获取页面静态资源
export function getResourceData(): { documentTitle: string, bannerSrc: string, footerCopyrightText: string } {
    return {
        documentTitle: "Polestar极星环保信息发布系统",
        bannerSrc: "https://cdn-dev.china.polestar.cn/resource/miniprogram/test/landing-page-banner.webp",
        footerCopyrightText: "极星汽车销售有限公司© 2023 版权所有"
    }
}
